import React, { useCallback, useState, useEffect, useRef } from 'react';

import Slide from "./Slide";
import * as UI from "./ui";

const INTERVAL_MS = 7000;

const Slideshow = ({ slideVideo, slides, height, width, aspectRatio, disableControls, disableCaptions }) => {
  const [ currentIndex, setCurrentIndex ] = useState(0);
  const timer = useRef(null);
  const isVideo = slideVideo ? currentIndex === 0 : false;
  const indexOffset = slideVideo ? 1 : 0;
  const controlsDisabled = (!slideVideo && slides.length === 1) || disableControls;
  const slideshowLength = slideVideo ? slides.length + 1 : slides.length;

  const setupTimer = useCallback(() => {
    const tick = () => {
      setCurrentIndex(lastIndex => (lastIndex + 1) % slideshowLength);
    };

    if (!isVideo) {
      timer.current = setInterval(tick, INTERVAL_MS);
    }
    return () => { clearTimeout(timer.current) }
  }, [slideshowLength, isVideo]);

  const resetTimer = useCallback(() => {
    clearTimeout(timer.current);
    // Wait a full interval before we start the clock ticking again.
    // Essentially doubles the interval for a manually accessed slide.
    timer.current = setTimeout(setupTimer, INTERVAL_MS);
  }, [setupTimer]);

  useEffect(setupTimer, [ slides, isVideo, setupTimer ]);

  const handlePrevious = useCallback(fromIndex => () => {
    resetTimer();

    if(fromIndex - 1 < 0) {
      return setCurrentIndex(slideshowLength - 1);
    }

    return setCurrentIndex(fromIndex - 1);
  }, [resetTimer, setCurrentIndex, slideshowLength]);

  const handleNext = useCallback(fromIndex => () => {
    resetTimer();

    return setCurrentIndex((fromIndex + 1) % slideshowLength);
  }, [resetTimer, setCurrentIndex, slideshowLength]);

  const handleNextAfterVideo = useCallback(fromIndex => () => {
    return setCurrentIndex((fromIndex + 1) % slideshowLength);
  }, [slideshowLength, setCurrentIndex]);

  if(slideshowLength === 0) {
    return null;
  }

  return (
    <UI.Layout>
      <UI.SlideGroup>
        {slideVideo && (
          <Slide
            slide={slideVideo}
            visible={currentIndex === 0}
            height={height}
            width={width}
            aspectRatio={aspectRatio}
            disableCaptions={disableCaptions}
            onPrevious={handlePrevious(0)}
            onNext={handleNextAfterVideo(0)}
            isVideo={true}
          />
        )}
        { slides.map((slide, index) => (
          <Slide
            key={slide.__typename + slide.id}
            slide={slide}
            visible={index + indexOffset === currentIndex}
            height={height}
            width={width}
            aspectRatio={aspectRatio}
            disableCaptions={disableCaptions}
            disableControls={controlsDisabled}
            onPrevious={handlePrevious(index + indexOffset)}
            onNext={handleNext(index + indexOffset)}
          />
        ))}
      </UI.SlideGroup>
    </UI.Layout>
  );
}

export default Slideshow;
