const trackShareWithGTag = ({ method, item_id, content_type = "text" }) => {
  window?.gtag('event', 'share', {
    method,
    content_type,
    item_id,
  });
};


export default trackShareWithGTag;
