import React from "react";

import CountdownWidget from "./CountdownWidget";
import { useSchedulePeriod } from "../../lib/schedule";
import Aside from "./Aside"
import Slideshow from "../Slideshow";
import Layout from "../Layout";

const Hero = ({ sweepstakes }) => {
  const period = useSchedulePeriod();

  // We enable hiding behavior only when the sweepstakes has not yet ended.
  const showAsideAbove = ["review", "open", "closing"].includes(period) && "phone";
  const isArchived = period === "archived";
  const slides = isArchived ?
    sweepstakes.slides.slice(0, 1) :
    [sweepstakes.activeFlashContest, ...sweepstakes.slides].filter(slide => slide?.image);
  const slideVideo = isArchived ? null : sweepstakes.slideVideo;

  return (
    <Layout.FullWidth fold>
      <CountdownWidget sweepstakes={sweepstakes} />

      <Layout.FlexibleColumn span={[7,15]} breakpoint="belowLaptop" spaced>
        <Slideshow slideVideo={slideVideo} slides={slides} width={880} aspectRatio={[16,9]} />
      </Layout.FlexibleColumn>

      <Layout.FixedColumn span={5} breakpoint="belowLaptop" showAbove={showAsideAbove} spaced>
        <Aside sweepstakes={sweepstakes} isArchived={isArchived} />
      </Layout.FixedColumn>
    </Layout.FullWidth>
  );
}

export default Hero;
