import styled from "styled-components";

import Image from "../Image";
import { default as Video } from "./SlideVideo";

export const Layout = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const SlideGroup = styled.div`
  width: 100%;
`;

export const Slide = styled.div`
  ${props => props.visible ? `
    position: relative;
    z-index: 1;
    opacity: 1;
  ` : `
    position: absolute;
    top: 0;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    ${props.$shouldAnimate && `transition: opacity ${props.fadeDuration}ms ease-in-out;`}
  `}
`;

export const SlideImage = styled(Image)`
  display: block;
  max-width: 100%;
`;

export const SlideVideo = styled(Video)`
  display: block;
  width: 100%;
`;

export const Footer = styled.div`
  display: ${({ disabled }) => disabled ? "none" : "flex"};
  background-color: #67778d;
  color: #ffffff;
`;

export const Caption = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  font-weight: 400;
  height: 60px;
  justify-content: center;
  line-height: 1.25em;
  padding: 12px;
  text-align: center;
  width: 100%;
  ${({ disabled }) => disabled ? "visibility: hidden;" : ""}

  @media screen and (max-width: 767px) {
    font-size: 0.75em;
  }
`;

export const Control = styled.button`
  border: none;
  background: none;
  color: rgba(255,255,255,0.85);
  font-size: 1.5em;
  padding: 0 20px;
  cursor: pointer;
  ${({ disabled }) => disabled ? "visibility: hidden;" : ""}

  @media screen and (max-width: 767px) {
    font-size: 1em;
  }
`;
