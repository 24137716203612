import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faSquareXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';

export const Layout = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0 5px;
`;

export const WidgetTitle = styled.div`
  min-width: 100%;
  font-weight: 500;
  font-size: 0.625em;
  line-height: 1.6em;
  text-transform: uppercase;
  margin-bottom: 2px;
`;

export const Icon = styled(FontAwesomeIcon)`
  height: 30px;
  width: 30px;
  cursor: pointer;
  transition-property: color;
  transition-duration: 0.4s;
 `;
 
export const FacebookIcon = styled(Icon).attrs({ icon: faFacebookSquare })`
  color: #43609C;

  &:hover {
    color: #324a79;
  }
`;

export const EmailIcon = styled(Icon).attrs({ icon: faEnvelopeSquare })`
  color: #59CBE8;

  &:hover {
    color: #41b0cf;
  }
`;

export const XIcon = styled(Icon).attrs({ icon: faSquareXTwitter })`
  color: #000000;

  &:hover {
    color: #555555;
  }
`;