import styled from "styled-components";
import { AdvancedVideo } from "@cloudinary/react";

export const Video = styled(AdvancedVideo)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Layout = styled.div`
  position: relative;
  display: flex;
  max-width: 100%;
  aspect-ratio: ${props => props.aspectRatio};
  background-color: #010101;
`;
