import { toMoneyString } from "../Money";

const trackDonationBeganWithGTag = ({ paymentIntent, donationLevel, promoCode, sweepstakes }) => {
  window?.gtag('event', 'donation_began', {
    event_category: 'sweepstakes',
    event_label: 'donation',
    currency: paymentIntent.currency,
    value: toMoneyString({ cents: paymentIntent.amount, format: '0.00'}),
    transaction_id: paymentIntent.id,
    coupon: promoCode && promoCode.name,
    items: [
      {
        item_id: donationLevel.id,
        item_name: `${toMoneyString({ cents: paymentIntent.amount })} Donation`,
      }
    ],
  });
};

const trackPurchaseWithGTag = ({ paymentIntent, donationLevel, promoCode, sweepstakes }) => {
  window?.gtag('event', 'purchase', {
    event_category: 'sweepstakes',
    event_label: 'donation',
    currency: paymentIntent.currency,
    value: toMoneyString({ cents: paymentIntent.amount, format: '0.00'}),
    transaction_id: paymentIntent.id,
    coupon: promoCode && promoCode.name,
    items: [
      {
        item_id: donationLevel.id,
        item_name: `${toMoneyString({ cents: paymentIntent.amount })} Donation`,
      }
    ],
  });
};

const trackPurchaseWithGoogleAds = ({ paymentIntent, donationLevel, promoCode, sweepstakes }) => {
  if(sweepstakes.googleAdsConversionId) {
    window?.gtag('event', 'conversion', {
      send_to: sweepstakes.googleAdsConversionId,
      value: toMoneyString({ cents: paymentIntent.amount, format: '0.00'}),
      currency: paymentIntent.currency,
      transaction_id: paymentIntent.id,
    });
  }
};

const trackPurchaseWithFacebook = ({ paymentIntent, donationLevel }) => {
  window?.fbq('track', 'Purchase', {
    value: toMoneyString({ cents: paymentIntent.amount, format: '0.00'}),
    currency: paymentIntent.currency,
    content_ids: [donationLevel.id],
  });
};

export const trackDonationBegan = (data) => {
  trackDonationBeganWithGTag(data);
}

export const trackPurchase = (data) => {
  trackPurchaseWithGTag(data);
  trackPurchaseWithGoogleAds(data);
  trackPurchaseWithFacebook(data);
};
