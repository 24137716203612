import React from "react";

import { buildVideoSources, buildCldPoster } from "./cloudinary.js";

import * as UI from "./ui";

const Video = ({ source, innerRef, width, aspectRatio, onPlay = () => {}, onPause = () => {}, ...rest }) => {

  const cldVideoSources = buildVideoSources(source);
  const cldPoster = buildCldPoster(source, width);

  if(!source) {
    return null;
  }

  return (
    <UI.Layout aspectRatio={aspectRatio.join("/")} width={width}>
      <UI.Video 
        cldPoster={cldPoster}
        sources={[]}
        innerRef={innerRef}
        onPlay={onPlay}
        onPause={onPause}
        autoPlay={true}
        preload="auto"
        controls
        {...rest} 
      >
        {cldVideoSources.map(({ type, src }) => <source key={type} src={src} type={type} />)} 
      </UI.Video>
    </UI.Layout>
  );
}

export default Video;
