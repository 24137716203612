import React from 'react';
import { useEffect, useRef, useCallback } from "react";

import Video from "../Video";

const eventPrefix = video => {
  if (video.mozRequestFullScreen) {
    return "mozfullscreenchange";
  } else if (video.webkitRequestFullscreen) {
    return "webkitfullscreenchange";
  }
  return "fullscreenchange";
}

const SlideVideo = ({ source, onNext, visible, ...rest }) => {
  const videoRef = useRef(null);
  const videoStateRef = useRef({ fullscreen: false, muted: true });
  const pauseTimeoutRef = useRef(null);

  const next = useCallback(() => {
    if (videoStateRef.current.fullscreen) {
      document.exitFullscreen();
      setTimeout(onNext, 500);
      return;
    }
    onNext();
  }, [onNext]);

  useEffect(() => {
    const controller = new AbortController();
    const video = videoRef.current;

    if (video) {
      const onVolumeChange = e => {
        videoStateRef.current.muted = e.target.muted;
      }

      const handleFullscreen = () => {
        if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement)  {
          videoStateRef.current.fullscreen = true;
          return;
        }

        videoStateRef.current.fullscreen = false;
      }

      video.addEventListener(eventPrefix(video), handleFullscreen, { signal: controller.signal });
      video.addEventListener("ended", next, { signal: controller.signal });
      video.addEventListener("volumechange", onVolumeChange, { signal: controller.signal });
    }

    return () => {
      controller.abort();
    }
  }, [next]);

  useEffect(() => {
    if (!visible) {
      videoStateRef.current.fullscreen = false;
    }
  }, [visible]);

  const handlePlay = useCallback(() => {
    clearTimeout(pauseTimeoutRef.current);
  }, []);

  const handlePause = useCallback(() => {
    pauseTimeoutRef.current = setTimeout(() => {
      next();
    }, 4000);
  }, [next])

  if(!source || !visible) {
    return null;
  }

  return <Video source={source} innerRef={videoRef} onPlay={handlePlay} onPause={handlePause} muted={videoStateRef.current.muted} {...rest} />;
};

export default SlideVideo;
