import React from 'react';
import * as UI from "./ui";
import trackShare from './utils';

const Email = ({ emailSubject, emailBodyText, url, sweepstakesId }) => {
  const onClick = () => {
    const root = 'mailto:';
    const newLine = '%0D%0A';
    const body = `${emailBodyText}${newLine}${newLine}${url}`;
    const mailtoUrl = `${root}?subject=${emailSubject}&body=${body}`;

    trackShare({ method: 'Email', item_id: sweepstakesId });
    window.location.href = mailtoUrl;
  }

  return (
    <UI.EmailIcon onClick={onClick} />
  );
}

export default Email;
