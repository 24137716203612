import React from 'react';
import * as UI from "./ui";
import trackShare from './utils';

const opts = ['width=500', 'height=500'];

const Twitter = ({ url, message, sweepstakesId }) => {
  const onClick = () => {
    trackShare({ method: 'X', item_id: sweepstakesId });
    window.open(`https://twitter.com/intent/tweet?text=${message}&url=${url}`, 'twitter', opts);
  }

  return (
    <UI.XIcon onClick={onClick} />
  );
}

export default Twitter;
