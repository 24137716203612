import React from 'react';
import trackShare from './utils';
import * as UI from "./ui";

const opts = ['width=500', 'height=500'];

const Facebook = ({ url, sweepstakesId }) => {
  const onClick = () => {
    trackShare({ method: 'Facebook', item_id: sweepstakesId });
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, 'facebook', opts);
  }

  return (
    <UI.FacebookIcon onClick={onClick}/>
  )
}

export default Facebook;
