import React from "react";
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react'
import styled from "styled-components";

const Layout = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50%;
`;

const Actions = styled.div`
  display: flex;
`;

const Action = styled.div`
  flex: 1;
  padding: 5px;
`;

const Button = styled.button`
  width: 100%;
  background-color: #0077cc;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 0.875em;
`;

const ErrorComponent = () => (
  <Layout>
    <p>Oops! An error occurred, and we've been notified.</p>
    <Actions>
      <Action>
        <Button onClick={() => window.location.reload()}>Refresh the Page</Button>
      </Action>
      <Action>
        <Button onClick={() => window.location.href = "mailto:support@tapkat.com"}>Contact Support</Button>
      </Action>
    </Actions>
  </Layout>
);

Honeybadger.beforeNotify((notice) => {
  if(/UnhandledPromiseRejectionWarning: Object Not Found Matching Id:/.test(notice.message)) {
    return false;
  }
});

const ErrorHandler = ({ children }) => {
  if(process.env.REACT_APP_HONEYBADGER_API_KEY) {
    const honeybadger = Honeybadger.configure({
      apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
      environment: process.env.REACT_APP_CONTEXT,
      revision: process.env.REACT_APP_COMMIT_REF,
    });

    return (
      <HoneybadgerErrorBoundary honeybadger={honeybadger} ErrorComponent={ErrorComponent}>
        {children}
      </HoneybadgerErrorBoundary>
    );
  }
  return children;
}

export default ErrorHandler;
